import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Spin, Table } from "antd";
import useSWR from "swr";
import moment from "moment";
import { useTranslation } from "react-i18next";
import type {
  ColumnsType,
  FilterValue,
  SorterResult,
} from "antd/lib/table/interface";
import { ITableUsers } from "../../types/ITableUsers";
import UserStatus from "../../enums/userTypes";
import parseNullToEmptyStringArray from "../../helpers/parseNullToEmptyStringArray";
import "./style.css";
import getTableColumns from "./getTableColumns";
import TableHeader from "./TableHeader";
import { Container, LoaderContainer, TableWrapper } from "./styles";
import getUsersUrl from "../../consts/getUsersUrl";

const defaultLimit = 100;

const TableUsers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  const [userData, setUserData] = useState<ITableUsers[]>([]);

  const { data, error, isValidating, isLoading } = useSWR(
    getUsersUrl() +
      `?sort=desc&limit=${searchParams.get("limit") || defaultLimit}&page=${
        searchParams.get("page") || "1"
      }`
  );

  const users = data?.page;
  const total = data?.metadata?.totalCount;

  useEffect(() => {
    if (!searchParams.has("page")) {
      setSearchParams({ page: "1" });
    } else if (!searchParams.has("limit")) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        limit: defaultLimit.toString(),
      });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (!data || isValidating) {
      return;
    }
    parsingFunction();
  }, [isValidating, data]);

  const parsingFunction = () => {
    const parsedArray = parseNullToEmptyStringArray(users);
    const parsedArrayWithStringAddress = parsedArray.map((item: any) => {
      const { company, dateOfBirth, address, ...rest } = item;
      let parsedCompany = "";
      if (!company) {
        parsedCompany = "none";
      } else {
        parsedCompany = company;
      }
      if (dateOfBirth && dateOfBirth !== "none") {
        return {
          ...rest,
          dateOfBirth: moment(dateOfBirth).format("DD-MM-YYYY"),
          address: `${address.number} ${address.street} ${address.locality} ${address.city} ${address.postCode}`,
          company: parsedCompany,
        };
      }
      return {
        ...rest,
        dateOfBirth: "0",
        address: `${address.number} ${address.street} ${address.locality} ${address.city} ${address.postCode}`,
        company: parsedCompany,
      };
    });

    setUserData(parsedArrayWithStringAddress);
  };

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ITableUsers>>({});
  const { t } = useTranslation();
  const columns: ColumnsType<ITableUsers> = getTableColumns(
    t,
    sortedInfo,
    filteredInfo,
    userData
  );

  const navigate = useNavigate();

  const handleChange: TableProps<ITableUsers>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setSearchParams({
      page: pagination?.current?.toString() || "1",
      limit: pagination?.pageSize?.toString() || defaultLimit.toString(),
    });
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<ITableUsers>);
  };

  const clearAll = () => {
    parsingFunction();
    setFilteredInfo({});
    setSortedInfo({});
  };

  const handleStatus = (status: UserStatus) => {
    if (status === UserStatus.CLOSED) {
      return "closed";
    }
    if (status === UserStatus.SUSPENDED) {
      return "suspended";
    }
    return "active";
  };

  if (error || !userData.length) {
    return (
      <LoaderContainer>
        <Spin size={"large"} />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <TableHeader clearAll={clearAll} />
      <TableWrapper>
        <Table
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: (e: any) => {
                if (e.target instanceof HTMLTextAreaElement) {
                  if (e.target.tagName === "TEXTAREA") {
                    return;
                  }
                }
                if (e.target instanceof HTMLButtonElement) {
                  if (e.target.id === "note") {
                    return;
                  }
                }
                if (e.target instanceof HTMLInputElement) {
                  if (e.target.tagName === "INPUT") {
                    return;
                  }
                }
                if (e.target instanceof HTMLButtonElement) {
                  if (e.target.id === "trial") {
                    return;
                  }
                }
                return navigate(`/users/${record.userId}${search}`);
              },
            };
          }}
          scroll={{ x: 2200 }}
          dataSource={userData}
          pagination={{
            onChange(page, pageSize) {
              setSearchParams({ page: page.toString() });
            },
            defaultCurrent: parseInt(searchParams.get("page") || "1", 10),
            defaultPageSize: parseInt(
              searchParams.get("limit") || defaultLimit.toString(),
              10
            ),
            showSizeChanger: true,
            pageSizeOptions: ["10", "25", "50", "100"],
            total: total,
          }}
          onChange={handleChange}
          loading={isLoading}
          rowClassName={(record, index) =>
            handleStatus(record.userStatus as UserStatus)
          }
        />
      </TableWrapper>
    </Container>
  );
};

export default TableUsers;
